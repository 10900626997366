import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { Flex, Loader } from "@mantine/core";
import { API_URLS } from "shared/Constants/api.const";
import http from "shared/utils/http";
import { useClubsContext } from "shared/ContextProviders/ClubsContextProvider/ClubsContextProvider";
import { ClubAddSubject } from "shared/Subjects";
import { S3_FOLDER_NAME } from "shared/Constants/general.const";

const CenterContext = createContext({
  Clubs: {},
  clubsData: {},
  handleSave: () => {},
  harbourData: {},
  isButtonDisabled: false,
  setIsButtonDisabled: () => {},
  uploadImage: () => {},
});

export const CenterContextProvider = ({ children }) => {
  const { selectedClub } = useClubsContext();

  const [isLoading, setIsLoading] = useState(true);
  const [clubsData, setClubsData] = useState([]);
  const [harbourData, setHarbourData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // GET Api call
  const fetchHarbourList = async (harborId) => {
    //API for Harbour Data

    const response = await http

      .get(`${API_URLS.GET_CLUBS_HARBOUR}/${harborId}?includeChildData=true`)
      .then((data) => data)
      .catch((error) => {});

    setHarbourData(response?.portalHarborResponseModel || []);
  };

  // Api call
  const Clubs = async () => {
    const clubId = selectedClub?.clubId;
    if (clubId) {
      const response = await http.get(
        `${API_URLS.GET_ALL_CLUBS}?clubId=${clubId}`
      );
      setClubsData(response?.clubs || []);

      setIsLoading(false);
    }
  };

  // Add Habour POST Call
  const handleSave = async (data) => {
    await http.post(API_URLS.ADD_HARBOUR, data);
  };

  const uploadImage = async (imageFile) => {
    const folderName = S3_FOLDER_NAME.HARBORS_IMAGES;
    const formData = new FormData();
    formData.append("files", imageFile);
    let res;

    res = await http.postFormData(
      `${API_URLS.UPLOAD_IMAGE}${folderName}`,
      formData
    );

    return res;
  };

  useEffect(() => {
    Clubs();
    const subscription = ClubAddSubject.subscribe((data) => {
      Clubs();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [selectedClub]);

  if (isLoading) {
    return (
      <Flex justify={"center"} align={"center"} w={"100%"} h={"70vh"}>
        <Loader size={"xl"} />
      </Flex>
    );
  }

  return (
    <CenterContext.Provider
      value={{
        Clubs,
        clubsData,
        handleSave,
        harbourData,
        fetchHarbourList,
        isButtonDisabled,
        setIsButtonDisabled,
        uploadImage,
      }}
    >
      {children}
    </CenterContext.Provider>
  );
};

export const useCenterContext = () => useContext(CenterContext);
