import {
  Box,
  Button,
  FileButton,
  Flex,
  Group,
  Image,
  Loader,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconRestore } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { FileToDataUrl } from "shared/utils/file";
import placeholder from "../../../../../../../assets/images/placeholder.png";
import { isEmail, useForm } from "@mantine/form";
import { useClubManagementContext } from "../../ClubManagement.context";
import toast from "react-hot-toast";
import { CountryList } from "shared/Constants/general.const";
import { PhoneInput } from "react-international-phone";

const AddClubs = ({ close }) => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const { addClub, uploadImage, addClubLoading, setAddClubLoading } =
    useClubManagementContext();

  const form = useForm({
    initialValues: {
      clubName: "",
      clubLogo: "",
      clubFirstName: "",
      clubLastName: "",
      clubStreetAddress: "",
      clubZipCode: "",
      clubCity: "",
      clubCountry: "",
      clubPhone: "",
      clubEmail: "",
      clubVat: "",
      commission: "5",
    },
    validate: {
      clubName: (value) => (value ? null : "Please enter club name"),
      //   clubLogo: (value) => (value ? null : "Please upload club logo"),
      clubFirstName: (value) => (value ? null : "Please enter club first name"),
      clubLastName: (value) => (value ? null : "Please enter club last name"),
      clubStreetAddress: (value) =>
        value ? null : "Please enter club street address",
      clubZipCode: (value) => (value ? null : "Please enter club zip code"),
      clubCity: (value) => (value ? null : "Please enter club city"),
      clubCountry: (value) => (value ? null : "Please enter club country"),
      clubPhone: (value) => (value ? null : "Please enter club phone"),
      clubEmail: isEmail("Please enter club email"),
      clubVat: (value) => (value ? null : "Please enter club vat"),
      commission: (value) => (value ? null : "Please enter commission"),
    },
  });
  useEffect(() => {
    if (file) {
      FileToDataUrl(file).then((url) => {
        setFileUrl(url);
      });
    }
  }, [file]);

  const resetImageState = () => {
    setFile(null);
    setFileUrl("");
  };

  const handleSave = async () => {
    setAddClubLoading(true);
    const postData = {
      clubName: form.values.clubName,
      clubLogo: form.values.clubLogo,
      clubManagerId: null,
      clubContactFirstName: form.values.clubFirstName,
      clubContactLastName: form.values.clubLastName,
      clubStreetAddress: form.values.clubStreetAddress,
      clubPostcode: form.values.clubZipCode,
      clubCity: form.values.clubCity,
      clubCountry: form.values.clubCountry,
      clubPhone: form.values.clubPhone,
      clubEmail: form.values.clubEmail,
      clubVAT: form.values.clubVat,
      clubMoxseaCommissionPercent: Number(form.values.commission),
    };

    if (file) {
      await uploadImage(file)
        .then(async (res) => {
          const logo = res?.uploadedFileUrls[0];
          postData.clubLogo = logo;
          await addClub(postData)
            .then(() => {
              toast("Save successful!", { appearance: "success" });
              close();
              setAddClubLoading(false);
            })
            .catch((error) => {
              toast(error.message || "Save failed. Please try again.", {
                appearance: "error",
              });
              setAddClubLoading(false);
            });
        })
        .catch((error) => {
          toast(error.message || "Save failed. Please try again.", {
            appearance: "error",
          });
        });
    } else {
      await addClub(postData)
        .then(() => {
          toast("Save successful!", { appearance: "success" });
          close();
          setAddClubLoading(false);
        })
        .catch((error) => {
          toast(error.message || "Save failed. Please try again.", {
            appearance: "error",
          });
          setAddClubLoading(false);
        });
    }
  };

  return (
    <>
      {addClubLoading ? (
        <Flex justify={"center"} align={"center"} h={"70vh"}>
          <Loader size={"xl"} />
        </Flex>
      ) : (
        <Flex direction="column" gap="md">
          <Box
            component="form"
            onSubmit={form.onSubmit((values) => {
              if (form.isValid()) {
                handleSave();
              }
            })}
          >
            <Flex direction="column" rowGap={12}>
              <TextInput
                withAsterisk
                label="Club Name"
                placeholder="Club Name"
                {...form.getInputProps("clubName")}
              />
              <Flex direction="column" justify="center" mt={10}>
                <Text size="sm" fw="500">
                  Club Logo
                </Text>
                <Flex justify="center">
                  <Image
                    radius="xl"
                    w={180}
                    h={120}
                    fit="contain"
                    src={fileUrl || placeholder}
                    style={{ border: "1px solid #cdcaca" }}
                    // className="m-auto"
                  />
                </Flex>
              </Flex>
              <Group justify="center" mt={10}>
                <FileButton
                  onChange={setFile}
                  accept="image/png, image/jpeg, image/jpg"
                >
                  {(props) => (
                    <Button {...props} color="gray">
                      Upload Logo
                    </Button>
                  )}
                </FileButton>
                <Button variant="default" ml={8} onClick={resetImageState}>
                  <Tooltip label="Reset Image">
                    <IconRestore />
                  </Tooltip>
                </Button>
              </Group>
              <TextInput
                label="Club Contact First Name"
                placeholder="Club Contact First Name"
                withAsterisk
                {...form.getInputProps("clubFirstName")}
              />
              <TextInput
                label="Club Contact Last Name"
                placeholder="Club Contact Last Name"
                withAsterisk
                {...form.getInputProps("clubLastName")}
              />
              <TextInput
                label="Club Street Address"
                placeholder="Club Street Address"
                withAsterisk
                {...form.getInputProps("clubStreetAddress")}
              />
              <TextInput
                label="Club PostCode"
                withAsterisk
                placeholder="Club PostCode"
                {...form.getInputProps("clubZipCode")}
              />
              <TextInput
                label="Club City"
                withAsterisk
                placeholder="Club City"
                {...form.getInputProps("clubCity")}
              />
              <Select
                label="Club Country"
                withAsterisk
                placeholder="Club Country"
                clearable
                searchable
                data={CountryList}
                {...form.getInputProps("clubCountry")}
              />
              <Flex direction="column">
                <Text size="sm" mb={4} fw={500}>
                  Phone
                  <span style={{ color: "red" }}> *</span>
                </Text>
                <PhoneInput
                  defaultCountry="de"
                  inputStyle={{
                    borderTopRightRadius: "24px",
                    borderBottomRightRadius: "24px",
                    width: "100%",
                  }}
                  {...form.getInputProps("clubPhone")}
                />
              </Flex>
              <TextInput
                label="Club Email"
                withAsterisk
                placeholder="Club Email"
                {...form.getInputProps("clubEmail")}
              />
              <TextInput
                label="Club VAT Number"
                withAsterisk
                placeholder="Club VAT Number"
                {...form.getInputProps("clubVat")}
              />
              <Select
                label="Clubs MOXSEA commission %"
                data={Array.from({ length: 101 }, (_, i) => `${i}`)}
                withAsterisk
                placeholder="Clubs MOXSEA commission %"
                {...form.getInputProps("commission")}
              />
            </Flex>
            <Flex justify="space-between" mt={12}>
              <Button onClick={close}>Cancel</Button>
              <Button type="submit">Save</Button>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default AddClubs;
