import { useMemo } from "react";
import { useDisclosure } from "@mantine/hooks";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { Flex, Title, Button, Modal, Loader } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import {
  UserManagementnContextProvider,
  useUserManagementContext,
} from "./UserManagement.context";

import AddUserDetails from "./Components/AddUserDetails";
import ViewUserDetails from "./Components/ViewUserDetails";
import {
  REACT_TABLE_STYLE_PROP,
  USER_ROLE,
} from "shared/Constants/general.const";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";

const UserManagementContainer = () => {
  const { userData, loading, getAllUserData } = useUserManagementContext();

  const { userPermissions } = useUserContext();
  const [opened, { open, close }] = useDisclosure(false);
  const [
    userDetailsOpened,
    { open: userDetailsOpen, close: userDetailsclose },
  ] = useDisclosure(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: "Details",
        size: 200,
        header: "Details",

        Cell: ({ row }) => (
          <Button
            onClick={() => {
              const userId = row.original.userId;
              // fetchUserDetails(userId);
              getAllUserData(userId);
              userDetailsOpen();
            }}
          >
            View
          </Button>
        ),
      },
      {
        accessorKey: "firstName",
        header: "First Name",
        size: 150,
      },
      {
        accessorKey: "lastName",
        size: 150,
        header: "Last Name",
      },
      {
        accessorKey: "clubOrMoxsea",
        size: 200,
        header: "Club/Moxsea",
      },
      {
        accessorKey: "role",
        size: 200,
        header: "Role",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: userData,
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: false,
    enableTopToolbar: true,
    initialState: { density: "xs" },
    mantinePaperProps: ({ table }) => ({
      style: REACT_TABLE_STYLE_PROP,
    }),
    getRowId: (row) => row.id,
  });

  return (
    <Flex direction="column">
      {loading ? (
        <Flex justify="center" align="center" h="70vh">
          <Loader color="blue" size="xl" />
        </Flex>
      ) : (
        <>
          <Flex columnGap={18} mb={8}>
            <Title order={3}>Administration - User Management</Title>
            <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              onClick={open}
              disabled={userPermissions.roleName === USER_ROLE.Clubworker.value}
            >
              Add New
            </Button>
          </Flex>
          <Modal
            opened={opened}
            onClose={close}
            size="lg"
            title="Add User Details"
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
          >
            <AddUserDetails close={close} />
          </Modal>
          <MantineReactTable table={table} />
          <>
            <Modal
              opened={userDetailsOpened}
              onClose={userDetailsclose}
              title="View User Details"
              size="lg"
            >
              <ViewUserDetails closeHandler={userDetailsclose} />
            </Modal>
          </>
        </>
      )}
    </Flex>
  );
};

const ClubManagement = () => {
  return (
    <UserManagementnContextProvider>
      <UserManagementContainer />
    </UserManagementnContextProvider>
  );
};

export default ClubManagement;
