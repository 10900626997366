import {
  Flex,
  TextInput,
  Select,
  Button,
  MultiSelect,
  Box,
  Text,
  Loader,
} from "@mantine/core";
import { useForm, isEmail } from "@mantine/form";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { USER_ROLE } from "shared/Constants/general.const";
import { useUserManagementContext } from "../../UserManagement.context";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "shared/ContextProviders/UserContextProvider/UserContextProvider";

/**
 * Compares two arrays and returns true if they are equal
 *
 * @param {array} a first array to compare
 * @param {array} b second array to compare
 * @returns {boolean} true if the arrays are equal, false otherwise
 */
const arrayEquals = (a, b) => {
  // Check if both are arrays
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false;
  }
  // Check if they have the same length
  if (a.length !== b.length) {
    return false;
  }
  // Compare each element
  return a.every((val, index) => val === b[index]);
};

const arrayConvert = (arr) => (Array.isArray(arr) ? arr : [arr]);

const formatDate = (dateString) => {
  if (!dateString) return "";
  return new Date(dateString)
    .toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
    .replace(",", "");
};

const ViewUserDetails = ({ closeHandler }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { userPermissions } = useUserContext();
  const { userDetailsData, editUserDetails } = useUserManagementContext();

  const {
    clubsList,
    regionsList,
    harborsList,
    updateRegionsList,
    updateHarborsList,
  } = useUserManagementContext();

  let userRoleData = [];
  if (userPermissions.roleName === USER_ROLE.MOXSEA_Support.value) {
    userRoleData = [
      {
        label: USER_ROLE.Clubmanager.label,
        value: USER_ROLE.Clubmanager.value,
      },
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  } else if (userPermissions.roleName === USER_ROLE.Clubmanager.value) {
    userRoleData = [
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  } else if (userPermissions.roleName === USER_ROLE.Clubworker.value) {
    userRoleData = [
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  } else {
    userRoleData = [
      {
        label: USER_ROLE.MOXSEA_Administrator.label,
        value: USER_ROLE.MOXSEA_Administrator.value,
      },
      {
        label: USER_ROLE.MOXSEA_Support.label,
        value: USER_ROLE.MOXSEA_Support.value,
      },
      {
        label: USER_ROLE.Clubmanager.label,
        value: USER_ROLE.Clubmanager.value,
      },
      { label: USER_ROLE.Clubworker.label, value: USER_ROLE.Clubworker.value },
    ];
  }

  const form = useForm({
    initialValues: {
      firstName: userDetailsData?.firstName || "",
      lastName: userDetailsData?.lastName || "",
      email: userDetailsData?.email || "",
      userRole: userDetailsData?.role || "",
      status: userDetailsData?.status || "",
      createdBy: userDetailsData?.createdBy || "",
      expiryDate: userDetailsData?.expiryDate || "",
      createdAt: userDetailsData?.createdAt || "",
      updatedAt: userDetailsData?.updatedAt || "",
      accessedClubs: userDetailsData?.accessedClubs || "",
      accessedRegions: userDetailsData?.accessedRegions || "",
      accessedHarbors: userDetailsData?.accessedHarbors || "",
    },
    validate: {
      firstName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      lastName: (value) =>
        value.length < 2 ? "Name must have at least 2 letters" : null,
      email: isEmail("Invalid email"),
      userRole: (value) => (!value ? "Please select User Role" : null),
      accessedClubs: (value, values) =>
        !value?.length &&
        (form.values.userRole === USER_ROLE.MOXSEA_Support.value ||
          form.values.userRole === USER_ROLE.Clubmanager.value ||
          form.values.userRole === USER_ROLE.Clubworker.value)
          ? "Club is required"
          : null,

      accessedRegions: (value, values) =>
        form.values.userRole === USER_ROLE.MOXSEA_Administrator.value &&
        USER_ROLE.MOXSEA_Support.value &&
        USER_ROLE.Clubmanager.value
          ? null
          : form.values.userRole === USER_ROLE.Clubworker.value &&
            !value?.length
          ? "Region is required"
          : null,

      accessedHarbors: (value, values) =>
        form.values.userRole === USER_ROLE.MOXSEA_Administrator.value &&
        USER_ROLE.MOXSEA_Support.value &&
        USER_ROLE.Clubmanager.value
          ? null
          : form.values.userRole === USER_ROLE.Clubworker.value &&
            !value?.length
          ? "Harbor is required"
          : null,
    },
  });

  /**
   * Fetch all user data and set the form values accordingly.
   * The accessedClubs is set as either a string or an array based on the user role.
   * If the user role is Clubmanager or Clubworker, the accessedClubs is set to the first element of the accessedClubs array.
   * If the user role is MOXSEA_Administrator, the accessedClubs is set to an array of all club IDs.
   * The form values are set with the user data, and the userId is set for the update API call.
   */
  const fetchAllUserData = async () => {
    if (userDetailsData) {
      // Set accessedclubs as either string or array based on user role
      let accessedClubs = userDetailsData.accessedClubs;

      // If the user role is Clubmanager or Clubworker, set the accessedClubs to the first element of the accessedClubs array.
      if (
        userDetailsData.role === USER_ROLE.Clubmanager.value ||
        userDetailsData.role === USER_ROLE.Clubworker.value
      ) {
        accessedClubs = userDetailsData.accessedClubs[0];
      }
      // If the user role is MOXSEA_Administrator, set the accessedClubs to an array of all club IDs.
      else if (userDetailsData.role === USER_ROLE.MOXSEA_Administrator.value) {
        accessedClubs = clubsList.map((club) => club.clubId);
      }

      // Set Form values
      form.setValues({
        firstName: userDetailsData.firstName || "",
        lastName: userDetailsData.lastName || "",
        email: userDetailsData.email || "",
        userRole: userDetailsData.role || "",
        accessedClubs: accessedClubs || "",
        accessedRegions: userDetailsData.accessedRegions || "",
        accessedHarbors: userDetailsData.accessedHarbors || "",
        status: userDetailsData.status || "",
        createdBy: userDetailsData.createdBy || "",
        expiryDate: userDetailsData.expiryDate || "",
        createdAt: userDetailsData.createdAt || "",
        updatedAt: userDetailsData.updatedAt || "",
      });
      setUserId(userDetailsData.userId);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAllUserData();
  }, [userDetailsData]);

  const saveUserDetails = (userId) => {
    setLoading(true);
    const role = form.values.userRole;
    const newUserDetails = {
      firstName: form.values.firstName,
      lastName: form.values.lastName,
      password: "12345",
      email: form.values.email,
      role: form.values.userRole,
      accessedClubs: form.values.accessedClubs || [],
      accessedRegions: form.values.accessedRegions || [],
      accessedHarbors: form.values.accessedHarbors || [],
      status: form.values.status,
    };

    if (role === USER_ROLE.Clubworker.value) {
      /** List of clubs that the user has access to */
      newUserDetails.accessedClubs =
        arrayConvert(form.values.accessedClubs) || [];

      /** List of regions that the user has access to */
      newUserDetails.accessedRegions = form.values.accessedRegions || [];

      /** List of harbors that the user has access to */
      newUserDetails.accessedHarbors = form.values.accessedHarbors || [];
    } else if (role === USER_ROLE.MOXSEA_Administrator.value) {
      /** List of all club IDs */
      newUserDetails.accessedClubs = clubsList.map((club) => club.clubId);
    } else if (role === USER_ROLE.MOXSEA_Support.value) {
      /** List of club IDs that the user has access to */
      newUserDetails.accessedClubs = form.values.accessedClubs || [];
    } else if (role === USER_ROLE.Clubmanager.value) {
      /** List of club IDs that the user has access to */
      newUserDetails.accessedClubs = Array(form.values.accessedClubs) || [];
    } else {
      /** Empty lists for all the fields */
      newUserDetails.accessedClubs = [];
      newUserDetails.accessedRegions = [];
      newUserDetails.accessedHarbors = [];
    }

    editUserDetails(newUserDetails, userId)
      .then((response) => {
        toast("Save successful!", { appearance: "success" });
        closeHandler();
        setLoading(false);
      })
      .catch((error) => {
        toast(error.message || "Save failed. Please try again.", {
          appearance: "error",
        });
        setLoading(false);
      });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    saveUserDetails(userId);
  };

  const handleCancelClick = () => {
    form.reset();
    setIsEditing(false);
    closeHandler();
  };

  const handleAllRegionsChange = (selectedRegions) => {
    if (selectedRegions && selectedRegions.length > 0) {
      updateHarborsList(selectedRegions);
    }

    form.setFieldValue("accessedRegions", selectedRegions);
    form.setFieldValue("accessedHarbors", []);
  };

  const handleClubChange = (selectedClubId) => {
    if (form.values.userRole === USER_ROLE.Clubworker.value) {
      updateRegionsList(selectedClubId);
    }

    form.setFieldValue("accessedClubs", selectedClubId);
    form.setFieldValue("accessedRegions", []);
    form.setFieldValue("accessedHarbors", []);
  };

  const handleUserRoleChange = (selectedUserRole) => {
    form.setFieldValue("userRole", selectedUserRole);

    form.setFieldValue("accessedClubs", []);
    form.setFieldValue("accessedRegions", []);
    form.setFieldValue("accessedHarbors", []);
  };

  const formattedExpiryDate = formatDate(form.values.expiryDate);
  const formattedUpdatedAtDate = formatDate(form.values.updatedAt);
  const formattedCreatedAtDate = formatDate(form.values.createdAt);

  return (
    <Flex direction="column" h="100%">
      {loading ? (
        <Flex justify={"center"} align={"center"}>
          <Loader size={"xl"} />
        </Flex>
      ) : (
        <Box
          component="form"
          onSubmit={form.onSubmit(() => {
            if (form.isValid()) {
              handleSaveClick();
            }
          })}
        >
          <Flex direction={"column"} rowGap={12}>
            <TextInput
              label="First Name"
              withAsterisk
              placeholder="First Name"
              readOnly={!isEditing}
              {...form.getInputProps("firstName")}
            />
            <TextInput
              label="Last Name"
              withAsterisk
              placeholder="Last Name"
              readOnly={!isEditing}
              {...form.getInputProps("lastName")}
            />
            <TextInput
              label="Email"
              withAsterisk
              readOnly={!isEditing}
              placeholder="Enter your email"
              {...form.getInputProps("email")}
            />
            <Select
              label="User Role"
              placeholder="User Role"
              data={userRoleData}
              readOnly={!isEditing}
              {...form.getInputProps("userRole")}
              onChange={(value) => {
                handleUserRoleChange(value);
              }}
            />
          </Flex>
          {form.values.userRole === USER_ROLE.MOXSEA_Support.value && (
            <Flex direction="column">
              <Flex align="center" mt="sm">
                <MultiSelect
                  withAsterisk
                  w="110%"
                  readOnly={!isEditing}
                  label="Accessed Clubs"
                  hidePickedOptions
                  placeholder={
                    form.values.accessedClubs?.length > 0
                      ? ""
                      : "Choose Club(s)"
                  }
                  clearable
                  data={clubsList?.map((club) => ({
                    label: club?.clubName,
                    value: club?.clubId,
                  }))}
                  value={form.values.accessedClubs}
                  {...form.getInputProps("accessedClubs")}
                />
                <Flex mt="md" w="30%" justify="flex-end">
                  <Button
                    variant="subtle"
                    disabled={!isEditing}
                    onClick={() => {
                      form.setFieldValue(
                        "accessedClubs",
                        clubsList?.map((club) => club?.clubId)
                      );
                    }}
                  >
                    <Text td="underline"> Select All</Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          )}
          {form.values.userRole === USER_ROLE.Clubworker.value && (
            <Flex direction="column" mt="sm">
              <Select
                label="Accessed Clubs"
                placeholder={
                  form.values.accessedClubs?.length > 0 ? "" : "Choose Club(s)"
                }
                withAsterisk
                readOnly={!isEditing}
                clearable
                data={clubsList?.map((club) => ({
                  label: club.clubName,
                  value: club.clubId,
                }))}
                {...form.getInputProps("accessedClubs")}
                onChange={(value) => handleClubChange(value)}
              />
              {form.values.accessedClubs && (
                <>
                  <Flex align="center" mt="sm">
                    <MultiSelect
                      withAsterisk
                      readOnly={!isEditing}
                      label="Accessed Regions"
                      hidePickedOptions
                      w="110%"
                      placeholder={
                        form.values.accessedRegions?.length > 0
                          ? ""
                          : "Choose Region(s)"
                      }
                      clearable
                      disabled={!form.values.accessedClubs}
                      data={regionsList?.map((region) => ({
                        label: region.regionName,
                        value: region.regionId,
                      }))}
                      {...form.getInputProps("accessedRegions")}
                      onChange={(value) => handleAllRegionsChange(value)}
                    />
                    <Flex mt="md" align="flex-end" w="30%" justify="flex-end">
                      <Button
                        variant="subtle"
                        disabled={!isEditing}
                        onClick={() => {
                          const selectedRegionsIds = regionsList.map(
                            (region) => region?.regionId
                          );
                          form.setFieldValue(
                            "accessedRegions",
                            selectedRegionsIds
                          );
                          if (
                            !arrayEquals(
                              form.values.allRegions,
                              selectedRegionsIds
                            )
                          ) {
                            handleAllRegionsChange(selectedRegionsIds);
                          }
                        }}
                      >
                        <Text td="underline">Select All</Text>
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex align="center" mt="sm">
                    <MultiSelect
                      withAsterisk
                      label="Accessed Harbors"
                      hidePickedOptions
                      readOnly={!isEditing}
                      w="110%"
                      placeholder={
                        form.values.accessedHarbors?.length > 0
                          ? ""
                          : "Choose Harbor(s)"
                      }
                      clearable
                      disabled={!form.values.accessedRegions?.length}
                      data={harborsList?.map((harbor) => ({
                        label: harbor.harborName,
                        value: harbor.harborId,
                      }))}
                      {...form.getInputProps("accessedHarbors")}
                    />
                    <Flex mt="md" align="flex-end" w="30%" justify="flex-end">
                      <Button
                        variant="subtle"
                        disabled={
                          !form.values.accessedRegions?.length || !isEditing
                        }
                        onClick={() => {
                          form.setFieldValue(
                            "accessedHarbors",
                            harborsList?.map((harbor) => harbor?.harborId)
                          );
                        }}
                      >
                        <Text td="underline">Select All</Text>
                      </Button>
                    </Flex>
                  </Flex>
                </>
              )}
            </Flex>
          )}
          {form.values.userRole === USER_ROLE.Clubmanager.value && (
            <Flex direction="column">
              <Select
                label="Accessed Clubs"
                mt="sm"
                placeholder={
                  form.values.accessedClubs?.length > 0 ? "" : "Choose Club(s)"
                }
                withAsterisk
                clearable
                readOnly={!isEditing}
                data={clubsList?.map((club) => ({
                  label: club.clubName,
                  value: club.clubId,
                }))}
                {...form.getInputProps("accessedClubs")}
                onChange={(value) => handleClubChange(value)}
              />
            </Flex>
          )}

          <Flex direction="column" rowGap={12} mt={18}>
            <Text>
              <span
                style={{
                  fontWeight: "600",
                  display: "inline-block",
                  width: "150px",
                }}
              >
                Status -
              </span>
              {form.values.status}
            </Text>
            <Text>
              <span
                style={{
                  fontWeight: "600",
                  display: "inline-block",
                  width: "150px",
                }}
              >
                Created By -
              </span>
              {form.values.createdBy}
            </Text>
            <Text>
              <span
                style={{
                  fontWeight: "600",
                  display: "inline-block",
                  width: "150px",
                }}
              >
                Updated Date -
              </span>
              {formattedUpdatedAtDate}
            </Text>
            <Text>
              <span
                style={{
                  fontWeight: "600",
                  display: "inline-block",
                  width: "150px",
                }}
              >
                Created Date -
              </span>
              {formattedCreatedAtDate}
            </Text>
            <Text>
              <span
                style={{
                  fontWeight: "600",
                  display: "inline-block",
                  width: "150px",
                }}
              >
                Expiry Date -
              </span>
              {formattedExpiryDate}
            </Text>
          </Flex>
          <Button
            mt={8}
            onClick={() => {
              navigate(`/forgot-password`);
            }}
          >
            Reset Password
          </Button>
          {isEditing ? (
            <Flex mt="md" justify="space-between">
              <Button
                onClick={() => {
                  handleCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </Flex>
          ) : (
            <Flex mt="md">
              <Button size="sm" onClick={handleEditClick}>
                Edit
              </Button>
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default ViewUserDetails;
